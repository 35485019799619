import React from "react"
import { css } from "@emotion/core"

import Container from "./container"
import ContactForm from "./contact-form"
import { Phone, Email } from "./constants"

const ContactMethod = ({ label, value, url }) => {
  const Tag = url ? "a" : "span"

  return (
    <div
      css={css`
        padding-right: 30px;
        padding-bottom: 30px;
        display: inline-block;
      `}
    >
      <label
        css={css`
          display: block;
          font-size: 0.8em;
          font-weight: bold;
          text-transform: uppercase;
        `}
      >
        {label}
      </label>
      <Tag
        href={url}
        css={css`
          color: inherit;
        `}
      >
        {value}
      </Tag>
    </div>
  )
}

const SectionContact = ({ id }) => (
  <section
    id={id}
    css={css`
      padding: 60px 0;
    `}
  >
    <Container>
      <h2
        css={css`
          font-size: 2.25rem;
        `}
      >
        Contact
      </h2>
      <div
        css={css`
          padding: 30px 0;
        `}
      >
        <ContactMethod
          label="Phone"
          value={Phone}
          url={`tel:${Phone.replace(/\D+/g, "")}`}
        />
        <ContactMethod label="Email" value={Email} url={`mailto:${Email}`} />
      </div>

      <ContactForm />
    </Container>
  </section>
)

export default SectionContact
