import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const AspectRatio = ({ x, y, element, children, className }) => {
  const Tag = element
  const ratio = (y / x) * 100

  return (
    <Tag
      className={className}
      css={css`
        padding-top: ${ratio}%;
        position: relative;
      `}
    >
      <div
        css={css`
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        `}
      >
        {children}
      </div>
    </Tag>
  )
}

AspectRatio.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  element: PropTypes.string,
}

AspectRatio.defaultProps = {
  x: 2,
  y: 1,
  element: `div`,
}

export default AspectRatio
