import React from "react"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import AspectRatio from "../components/aspect-ratio"
import Reviews from "../components/reviews"
import Contact from "../components/contact"
import Map from "../components/map"
import { Colors } from "../components/constants"
import { mapStyles } from "../components/styles"
import Button from "../components/button"

const IndexPage = () => (
  <Layout>
    <SEO title="Mobile Notary Public - JustNotaries" />
    <Container
      element="section"
      css={css`
        padding-top: 60px;
        padding-bottom: 60px;
      `}
    >
      <AspectRatio
        x={2}
        y={1}
        css={css`
          background: ${Colors.grey1};
          margin-bottom: 30px;
        `}
      />
      <p>Hello!</p>
      <p>
        I am a certified notary public that has been serving Los Angeles, Inland
        Empire & surrounding areas for over 14 years.
      </p>
      <p>
        Please feel free to{" "}
        <a
          href="#contact"
          css={css`
            color: inherit;
            text-decoration: underline;
          `}
        >
          contact
        </a>{" "}
        me or{" "}
        <a
          href="https://calendly.com/loganstellway/30min?back=1&month=2020-02"
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            color: inherit;
            text-decoration: underline;
          `}
        >
          schedule an appointment
        </a>
        .
        <br />I look forward to working with you!
      </p>
      <p>Best Regards,</p>
      <p>Jorge Castro</p>

      <div
        css={css`
          text-align: center;
          padding: 30px 0 0;
        `}
      >
        <Button
          element="a"
          href="/how-it-works"
          outline={true}
          css={css`
            margin: 0 5px;
          `}
        >
          How it Works
        </Button>
        <Button
          element="a"
          href="/services"
          outline={true}
          css={css`
            margin: 0 5px;
          `}
        >
          Notary Services
        </Button>
      </div>
    </Container>

    <Reviews />
    <Contact id="contact" />
    <Map
      x={1}
      y={1}
      zoom={10}
      css={css`
        @media (min-width: 768px) {
          padding-top: 50%;
        }
        @media (min-width: 960px) {
          padding-top: 33%;
        }
      `}
      handleApiLoaded={({ map, maps }) => {
        new maps.Circle({
          strokeColor: "#BF0A30",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#BF0A30",
          fillOpacity: 0.15,
          map: map,
          center: map.center,
          radius: 40000,
        })
      }}
      options={{
        styles: mapStyles,
      }}
    />
  </Layout>
)

export default IndexPage
