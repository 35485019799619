import PropTypes from "prop-types"
import React from "react"
import GoogleMapReact from "google-map-react"

import AspectRatio from "./aspect-ratio"
import { GoogleMapsKey, ServiceCoordinates } from "./constants"

const Map = ({ center, zoom, x, y, handleApiLoaded, options, className }) => (
  <AspectRatio x={x} y={y} className={className}>
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{ key: GoogleMapsKey }}
      defaultCenter={center}
      defaultZoom={zoom}
      onGoogleApiLoaded={handleApiLoaded}
      options={options}
    ></GoogleMapReact>
  </AspectRatio>
)

Map.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  center: PropTypes.object,
  zoom: PropTypes.number,
  handleApiLoaded: PropTypes.func,
  options: PropTypes.object,
}

Map.defaultProps = {
  x: 2,
  y: 1,
  center: ServiceCoordinates,
  zoom: 14,
  handleApiLoaded: null,
  options: {},
}

export default Map
