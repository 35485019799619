import React from "react"
import { css } from "@emotion/core"
import { Star } from "@material-ui/icons"

import Container from "./container"
import { Colors, Reviews } from "./constants"

const SectionReviews = () => (
  <section
    css={css`
      color: ${Colors.white};
      background: ${Colors.primary};
    `}
  >
    {Reviews.map(({ rating, message, author }, index) => {
      const Stars = []
      for (let i = 0; i < rating; i++) {
        Stars.push(<Star key={i} />)
      }

      return (
        <Container
          css={css`
            padding: 60px 30px;
            text-align: center;
          `}
          key={index}
        >
          {Stars}
          <blockquote
            css={css`
              margin: 0;
              padding: 30px;
              display: block;
              font-size: 1.25em;
              font-style: italic;
              line-height: 1.5;
            `}
          >
            "{message}"
          </blockquote>
          <span
            css={css`
              display: block;
              font-size: 0.8em;
              font-weight: bold;
              text-transform: uppercase;
            `}
          >
            - {author}
          </span>
        </Container>
      )
    })}
  </section>
)

export default SectionReviews
